import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/login",
    redirect: "/",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/desktop",
    name: "desktop",
    component: () => import("../views/desktop.vue"),
  },
];
const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});
router.beforeEach((to, from, next) => {
  if (to.path == "/desktop") {
    let token = localStorage.getItem("token");
    if (token) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});


export default router;
