export default class {
  // 设置
  static set(key, value) {
    localStorage.setItem(key, value);
  }
  //获得
  static get(key) {
    return localStorage.getItem(key);
  }
  //移除
  static remove(key) {
    localStorage.removeItem(key);
  }
}
