import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import * as VueWindow from "@hscmap/vue-window";
import { install } from "@icon-park/vue/es/all";
import './plugins/element.js'
import config from "../public/config";
import storage from "./plugins/storage";
import md5 from "js-md5";
Vue.prototype.$md5 = md5;
Vue.prototype.$storage = storage;
Vue.prototype.$config = config;
install(Vue, "i");
Vue.use(VueWindow);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
