//
//图片的请求地址
//

// const baseUrl = "http://localhost:801";
const baseUrl = "https://server.xart.top";
// const baseUrl = "http://1.15.65.243:801";

module.exports = {
  baseUrl: baseUrl,
};
